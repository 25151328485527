import axios from "axios";
import { OpenAIStream } from "./openAIStream.js";
const apiKey = process.env.REACT_APP_OPENAI_API_KEY;

const openai = axios.create({
  baseURL: "https://api.openai.com/v1",
  headers: {
    Authorization: `Bearer ${apiKey}`,
    "Content-Type": "application/json",
  },
});

const moderateText = async (params, setShowModal) => {
  const inputText = Object.values(params).join(' ');
  const response = await openai.post("/moderations", { input: inputText });

  // Log the API request and response to the server
  const logData = {
    endpoint: "/moderations",
    request: {
      method: "POST",
      headers: {
        Authorization: `Bearer ${apiKey}`,
        "Content-Type": "application/json",
      },
      body: { input: inputText },
    },
    response: {
      status: response.status,
      data: response.data,
    },
    timestamp: new Date().toISOString(),
  };

  if (response.data.rejected) {
    setShowModal(true);
  }

  console.log('moderation: ' + response.data.results[0].flagged);
  return response.data.results[0].flagged;
};
const generateText = async (
  params,
  setShowModal,
  onUpdate,
  isSystemMessage = false,
  useSecret = false,
  includeRecentMessages = false,
  waitForCompletion = false,
  skipModeration = false
) => {
  console.log("variables passed:", params);

  try {
    const isFlagged = skipModeration ? false : await moderateText(params, setShowModal);

    if (!isFlagged) {
      const content = Object.values(params).join(" ");

      const messages = [
        { role: "user", content: content },
      ];

      if (isSystemMessage) {
        const secret = useSecret ? process.env.REACT_APP_SECRET : "";
        messages.unshift({ role: "system", content: "generate content for audience of 6-17 year olds, do not refer to ages or young. Keep responses at a 6th grade reading level and as brief as possible to explain the topic unless the user asks for a longer explaination" + secret });
      }
      if (includeRecentMessages && params.recentMessages && params.recentMessages.length > 0) {
        prompt += "\n\nPrevious Messages:\n";
        prompt += params.recentMessages.map(({ author, text }) => `${author.username}: ${text}`).join("\n");
        prompt += "\n";
      }

      const payload = {
        model: "gpt-3.5-turbo",
        messages: messages,
        user: params.username,
        temperature: 0,
        max_tokens: 2000,
      };
console.log(payload)
      if (waitForCompletion) {
        const response = await openai.post("/chat/completions", payload);
        console.log("OpenAI///"+response.data.choices[0].message.content)
        return response.data.choices[0].message.content;
      } else {
        const streamPayload = { ...payload, stream: true };
        const stream = await OpenAIStream(streamPayload, apiKey);
        const reader = stream.getReader();
        const decoder = new TextDecoder();

        let isFirstChunk = true;
        let bufferedText = '';
        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          const text = decoder.decode(value);
          bufferedText += text;
        
          const lastSpaceIndex = bufferedText.lastIndexOf(' ');
          if (lastSpaceIndex > 0) {
            const outputText = bufferedText.slice(0, lastSpaceIndex);
            bufferedText = bufferedText.slice(lastSpaceIndex + 1);
            onUpdate(outputText.trim(), isFirstChunk, false);
            isFirstChunk = false;
          }
        }
        onUpdate(bufferedText.trim(), false, true);
      }
    } else {
      console.log("Message is flagged. Not generating inputText.");
      setShowModal(true);
      return false;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export default generateText;
