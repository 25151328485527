import React, { useEffect, useState } from "react";
import generateText from "../openai.js";
import { ProgressBar } from "react-bootstrap";
import DiffMatchPatch from "diff-match-patch";

const Essay = ({ user }) => {
  const [essayText, setEssayText] = useState("");
  const [generatedText, setGeneratedText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formattedText, setFormattedText] = useState("");
  const [progress, setProgress] = useState(0);
  const [errorText, setErrorText] = useState("");
  const promptEssay = "Edit this essay and display the edited version, then write the following headers and provide the required information:\n- What needed to be fixed:\n- 5 steps to improve the essay (separated with a line break):\nEssay: ";

  useEffect(() => {
    let progressInterval;
    if (isLoading) {
      progressInterval = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 1));
      }, 60000 / 100);
    } else {
      setProgress(0);
    }
    return () => clearInterval(progressInterval);
  }, [isLoading]);

  const dmp = new DiffMatchPatch();

  const highlightDifferences = (originalText, editedText) => {
    const diffs = dmp.diff_main(originalText, editedText);
    dmp.diff_cleanupSemantic(diffs);
    return dmp.diff_prettyHtml(diffs);
  };

  const handleInputChange = (event) => {
    const text = event.target.value;
    const words = text.split(/\s+/);
    if (words.length <= 500) { // limit to 500 words
      setEssayText(text);
    } else {
      setEssayText(words.slice(0, 500).join(" "));
      alert('Text has been truncated to 500 words.'); // Alert if text is truncated
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    let response;
    try {
      response = await generateText({
        inputText: promptEssay + essayText,
        username: user.username,
      },
      true, // Dummy function for setShowModal
      false, // Dummy function for onUpdate
      false,
      false,
      false,
      true, // Set waitForCompletion to true
      true // Set skipModeration to true (optional, depends on your use case)
    );
  
    console.log("API Response:", response);
  
    const instructionsIndex = response.indexOf("\n\nWhat needed to be fixed:");
    const suggestionsIndex = response.indexOf("5 steps to improve the essay:");
  
    if (instructionsIndex === -1 || suggestionsIndex === -1) {
      throw new Error('Invalid response format');
    }
  
    const editedEssay = response.slice(0, instructionsIndex).trim();
    const instructions = response
      .slice(instructionsIndex + 26, suggestionsIndex)
      .trim()
      .split("\n- ")
      .slice(1);
    const suggestions = response
      .slice(suggestionsIndex + 27)
      .trim()
      .split("\n");
  
    console.log("Edited Essay, Instructions, and Suggestions:", editedEssay, instructions, suggestions);
  
    const formattedInstructions = instructions
      .map((instruction) => `<li>${instruction}</li>`)
      .join("");
  
    const formattedSuggestions = suggestions
      .map((suggestion) => `<li>${suggestion}</li>`)
      .join("");
  
    const formattedResponse = `
      <p><strong>Edited Essay:</strong></p>
      <p>${highlightDifferences(essayText, editedEssay)}</p>
      <p><strong>What needed to be fixed:</strong></p>
      <p> ${formattedInstructions} </p>
      <h5> Edited Essay </h5>
      <p> ${editedEssay} </p>
      <p ${editedEssay} </p>
      <p><strong>5 steps to improve the essay:</strong></p>
      <ul>
        ${formattedSuggestions}
      </ul>
    `;
  
    console.log("Raw Response:", response);
    console.log("Formatted Response:", formattedResponse);
  
    setGeneratedText(response);
    setFormattedText(formattedResponse);
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    let errorMessage = `<p>Error during formatting, unformatted response shown</p>`;
    setFormattedText(errorMessage + response);
    alert(`Error during formatting, Unformatted text is shown below.`);
  }
  };
  

return (
  <div className="container py-5" style={{ background: "linear-gradient(to right, #1a1a1a, #0d47a1, #1a1a1a)" }}>
    <div className="row" style={{ color: "white" }}>
      <div className="col-md-6 my-5">
        <h1 className="text-center mb-5" tabIndex="0">Essay Genie 🧞</h1>
        <ol>
          <li>Type or copy and paste your essay in the text box below.</li>
          <li>Click the "Summon the Genie" button.</li>
          <li>Wait for the magic to happen! (it may take a few seconds)</li>
          <li>Review the generated text.</li>
          <li>Edit the generated text to make it better with the suggestions provided!</li>
        </ol>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="essayTextarea">Enter your essay text here:</label>
            <textarea
              className="form-control input-text"
              id="essayTextarea"
              rows="10"
              placeholder="Essay goes here, 500 words or less no spaces between paragraphs, break up your essay if longer"
              value={essayText}
              onChange={handleInputChange}
            />
          </div>
          <div className="text-left">
            <button
              className="btn btn-primary btn-lg mb-5"
              type="submit"
              disabled={isLoading}
              aria-label="Submit your essay for suggestions"
            >
              {isLoading ? "Loading..." : "Summon the Genie 🧞"}
            </button>
          </div>
        </form>
      </div>
      <div className="col-md-6 my-5">
        {isLoading && (
          <div className="text-center">
            <p className="text-lg text-gray-700" tabIndex="0" aria-live="polite">Summoning the Genie... 🧞</p>
            <ProgressBar now={progress} animated striped variant="info" aria-label="Loading progress" />
          </div>
        )}

        {formattedText && (
          <div className="form-group bg-light p-5 rounded output-text" style={{ height: "500px", overflowY: "scroll" }} tabIndex="0">
            <h3 className="text-center mb-3">Genie's Thoughts ... 🧞</h3>
            <div dangerouslySetInnerHTML={{ __html: formattedText || generatedText}} />
          </div>
        )}
      </div>
    </div>
  </div>
);

};

export default Essay;
