import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, ListGroup, Image, Alert } from 'react-bootstrap';

const EmojiStories = ({ user }) => {
  const [generatedStory, setGeneratedStory] = useState('');
  const [userGuess, setUserGuess] = useState('');
  const [score, setScore] = useState(0);
  const [highScores, setHighScores] = useState([]);
  const [showAnswer, setShowAnswer] = useState(false);
  const [generatedEmojis, setGeneratedEmojis] = useState([]);
  const [answer, setAnswer] = useState('');
  const [hint, setHint] = useState('');
  const [round, setRound] = useState(1);
  const [error, setError] = useState('');
 
  const movieEmojis = [
    {
      title: 'Toy Story',
      emojis: ['🤠', '👨‍🚀', '👨‍👩‍👧‍👦', '🐶', '🎉'],
      hint: 'A cowboy doll is profoundly threatened and jealous when a new spaceman figure supplants him as top toy in a boy\'s room.'
    },
    {
      title: 'The Incredibles',
      emojis: ['🦸‍♂️', '👩‍👧‍👦', '🏠', '🔥', '💪'],
      hint: 'A family of undercover superheroes, while trying to live the quiet suburban life, are forced into action to save the world.'
    },
    {
      title: 'Finding Nemo',
      emojis: ['🐟', '🔍', '🚣‍♂️', '🦈', '👨‍👦'],
      hint: 'After his son is captured in the Great Barrier Reef and taken to Sydney, a timid clownfish sets out on a journey to bring him home.'
    },
    {
      title: 'The Lion King',
      emojis: ['🦁', '👑', '🌅', '🐗', '🐒'],
      hint: 'A young lion prince flees his kingdom after the murder of his father, while his uncle tries to assume the throne for himself.'
    },
    {
      title: 'Up',
      emojis: ['🎈', '🏠', '🐕', '🏞️', '👴'],
      hint: 'Seventy-eight year old Carl Fredricksen travels to Paradise Falls in his home equipped with balloons, inadvertently taking a young stowaway.'
    },
    {
      title: 'Moana',
      emojis: ['🌊', '🚣‍♀️', '🐔', '🌺', '🌴'],
      hint: 'In Ancient Polynesia, when a terrible curse incurred by the Demigod Maui reaches Moana\'s island, she answers the Ocean\'s call to seek out the Demigod to set things right.'
    },
    {
      title: 'Coco',
      emojis: ['🎸', '💀', '👦', '🐕', '🎶'],
      hint: 'Aspiring musician Miguel, confronted with his family\'s ancestral ban on music, enters the Land of the Dead to find his great-great-grandfather, a legendary singer.'
    },
    {
      title: 'Zootopia',
      emojis: ['🐰', '🐯', '🕵️‍♀️', '🦊', '👮'],
      hint: 'In a city of anthropomorphic animals, a rookie bunny cop and a cynical con artist fox must work together to uncover a conspiracy.'
    },
    {
      title: 'Monsters, Inc.',
      emojis: ['👾', '🚪', '👻', '👷‍♂️', '👶'],
      hint: 'In order to power the city, monsters have to scare children so that they scream. However, the children are toxic to the monsters, and after a child gets through, two monsters realize things may not be what they think.'
    },
    {
      title: 'WALL-E',
      emojis: ['🤖', '🌎', '🚀', '💚', '🎶'],
      hint: 'In the distant future, a small waste-collecting robot inadvertently embarks on a space journey that will ultimately decide the fate of mankind.'
    },
    {
      title: 'Frozen',
      emojis: ['❄️', '👸', '🎶', '🏰', '☃️'],
      hint: 'When the newly-crowned Queen Elsa accidentally uses her power to turn things into ice to curse her home in infinite winter, her sister Anna teams up with a mountain man, his playful reindeer, and a snowman to change the weather condition.'
    },
    {
      title: 'Inside Out',
      emojis: ['😂', '💔', '🤯', '👧', '👭'],
      hint: 'After young Riley is uprooted from her Midwest life and moved to San Francisco, her emotions - Joy, Fear, Anger, Disgust and Sadness - conflict on how best to navigate a new city, house, and school.'
    },
    {
      title: "Jurassic Park",
      emojis: ["🦖", "🦕", "🌿", "🔒", "🌧️"],
      hint: "A theme park suffers a major power breakdown that allows its cloned dinosaur exhibits to run amok."
    },
    {
      title: "Pirates of the Caribbean",
      emojis: ["⚓️", "🏴‍☠️", "💰", "🗝️", "🌊"],
      hint: "Captain Jack Sparrow searches for the trident of Poseidon while being pursued by an undead sea captain and his crew."
    },
    {
      title: "Harry Potter",
      emojis: ["🧙", "🔮", "🎓", "🐍", "👓"],
      hint: "A young boy discovers he's a wizard and attends a school of witchcraft and wizardry, where he must stop a dark lord from rising to power."
    },
    {
      title: "Star Wars",
      emojis: ["⚔️", "🪐", "👽", "💫", "🚀"],
      hint: "A young farm boy embarks on a mission to rescue a princess and defeat the evil empire with the help of a rogue smuggler and a wise old Jedi."
    },
    {
      title: "Ghostbusters",
      emojis: ["👻", "🚫", "📞", "🚫", "🔥"],
      hint: "A team of scientists start a ghost-catching business in New York City and stumble upon a plan to summon an ancient evil that threatens the world."
    },
    {
      title: "The Wizard of Oz",
      emojis: ["🐶", "👧", "🌪️", "👠", "👸"],
      hint: "Dorothy Gale is swept away from a farm in Kansas to a magical land of Oz in a tornado and embarks on a quest with her new friends to see the Wizard who can help her return home."
    },
    {
      title: "Indiana Jones",
      emojis: ["🏺", "👨‍🏫", "💎", "🗺️", "🐍"],
      hint: "Archaeologist and adventurer Indiana Jones is hired by the US government to find the Ark of the Covenant before the Nazis can obtain its awesome powers."
    },
    {
      title: "The Matrix",
      emojis: ["🕶️", "💊", "👩‍💻", "🕰️", "🔫"],
      hint: "A computer programmer discovers he is living in a simulated reality created by machines, and joins a rebellion to break free and save humanity."
    },
    {
      title: "The Terminator",
      emojis: ["🤖", "👨‍🦲", "🕰️", "🔫", "🚀"],
      hint: "A cyborg assassin is sent back in time to kill a waitress, whose unborn son is destined to lead the human resistance against the machines."
    }
  ];
 movieEmojis.forEach(movie => {
    movie.answer = movie.title;
  });
  
  // Now you can use the movieEmojis array with the answer property set 
  
  const getRandomMovieEmojis = () => {
    const randomIndex = Math.floor(Math.random() * movieEmojis.length);
    const { title, emojis, hint } = movieEmojis[randomIndex];
    return { title, emojis, hint };
  };

  const parseWords = (text) => {
    return text.trim().split(/\s+/);
  };

  const displayParsedEmojis = (emojis) => {
    return emojis.map((emoji, index) => (
      <span key={`emoji-${index}`} role="img" aria-label="emoji">
        {emoji}
      </span>
    ));
  };

  const nextRound = () => {
    if (round < 5 && showAnswer) {
      setRound(round + 1);
      setShowAnswer(false);
      setUserGuess('');
      setScore(0);
      generateEmojiStory();
    }
  };

  useEffect(() => {
    const storedHighScores = localStorage.getItem('emojiGameHighScores');
    if (storedHighScores) {
      setHighScores(JSON.parse(storedHighScores));
    }
  }, []);

  const saveHighScore = (name) => {
    const newHighScores = [...highScores, { name, score }];
    newHighScores.sort((a, b) => b.score - a.score);
    setHighScores(newHighScores);
    localStorage.setItem('emojiGameHighScores', JSON.stringify(newHighScores));
  };
  const generateEmojiStory = () => {
    const { title, emojis, hint } = getRandomMovieEmojis();
    // Use the title to generate a story
const words = parseWords(title);
let story = '';
for (let i = 0; i < words.length; i++) {
const word = words[i];
const emojiIndex = Math.floor(Math.random() * emojis.length);
const emoji = emojis[emojiIndex];
const parsedEmojis = displayParsedEmojis(emojis.slice(0, emojiIndex + 1));
story += `${word} ${parsedEmojis}` ;
}
setGeneratedStory(story.trim());
setGeneratedEmojis(emojis);
setAnswer(title.toLowerCase());
setHint(hint);
};

const handleGuessSubmit = (event) => {
  event.preventDefault();
  if (!userGuess) {
    setError("Please enter your guess!");
    return;
  }
  const guess = userGuess.toLowerCase();
  if (guess === answer) {
    setScore(score + 1);
    setShowAnswer(true);
    setError("");
    if (round === 5) {
      saveHighScore(user);
    }
  } else {
    setError("Sorry, your guess is incorrect!");
  }
};
    
    return (
      <div className="game-container">
      <Container
        className="emoji-stories py-5 d-flex flex-column justify-content-center align-items-center"
        style={{
          backgroundImage: "url(./emojibackground.png)",
          backgroundSize: "cover",
          minHeight: "100vh",
          backgroundColor: "rgba(34, 40, 49, 0.85)",
        }}
      >
          <Row className="rowgame-container w-100">
          <Col xs={4} md={4} className="high-scores-col">
            {/* High Scores */}
            
          </Col>
            <Col xs={8} md={8} className="game-col">
              <h1 className="game-title text-center text-white mb-5" style={{ fontFamily: "'Montserrat', sans-serif" }}>
                Emoji Movie Story, Press the button and make a guess!
              </h1>
              <div className="emoji-buttons-container text-center mb-4">
                <Button
                  onClick={generateEmojiStory}
                  className="generate-emoji-button mb-3"
                  style={{ backgroundColor: "#1a508b", borderColor: "#1a508b" }}
                >
                  <h2>Generate Emoji Story!</h2>
                </Button>
              </div>
              {generatedEmojis.length > 0 && (
                <div className="emoji-story-container">
                  <h3 className="score-board text-white mb-3" style={{ fontFamily: "'Montserrat', sans-serif" }}>
                  Round: {round} | Score: {score}{" "}
                  </h3>
                  <h4 className="hint-text bg-white text-dark mb-3 p-2 rounded">The hint: {hint}</h4>
                  <div className="emoji-story bg-white p-2 mb-3 rounded d-flex justify-content-center align-items-center">
                    {displayParsedEmojis(generatedEmojis)}
                  </div>
                  {showAnswer ? (
                    <div className="answer-container bg-white text-dark p-3 rounded">
                      <h3 className="answer-result">
                        Score: {score}{" "}
                        {score >= 8 ? (
                          <span role="img" aria-label="winner-emoji">
                            🎉 WINNER WINNER! 🎉
                          </span>
                        ) : (
                          `${score}/8 correct words`
                        )}
                      </h3>
                      <p className="answer-text">Answer: {answer}</p>
                      <div className="buttons-container d-flex justify-content-between">
                        <Form onSubmit={() => saveHighScore(prompt('Enter your name'))}>
                          <Button type="submit" className="save-score-button" style={{ backgroundColor: "#00cc66", borderColor: "#00cc66" }}>
                            Save High Score
                          </Button>
                        </Form>
                        <Button onClick={nextRound} disabled={round >= 5} className="next-round-button" style={{ backgroundColor: "#cc3300", borderColor: "#cc3300" }}>
                          Next Round
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <Form onSubmit={handleGuessSubmit} className="guess-form">
                      <Form.Group controlId="userGuess" className="mb-3">
                        <Form.Label className="bg-white text-dark p-2 rounded">Your guess:</Form.Label>
                        <Form.Control
                          type="text"
                          name="userGuess"
placeholder="Enter your guess here"
value={userGuess}
onChange={(e) => setUserGuess(e.target.value)}
/>
</Form.Group>
{error && <Alert variant="danger">{error}</Alert>}
<Button
  type="submit"
  className="guess-button mb-3"
  style={{ backgroundColor: "#ffcc00", borderColor: "#ffcc00" }}
  onClick={() => setShowAnswer(true)}
>
  Guess!
</Button>
<Button onClick={() => setShowAnswer(true)} className="show-answer-button" style={{ backgroundColor: "#9900cc", borderColor: "#9900cc" }}>
Show Answer
</Button>
</Form>
)}
</div>
)}
</Col>
</Row>
</Container>

  </div>
);}
export default EmojiStories;
    
