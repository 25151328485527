import React, { useState } from "react";
import generateText from "../openai.js";
import { ProgressBar } from "react-bootstrap";

const Citation = ({ user }) => {
  const [citationStyle, setCitationStyle] = useState("APA");
  const [inputText, setInputText] = useState("");
  const [generatedText, setGeneratedText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [formattedText, setFormattedText] = useState("");

  const promptCitation = (style, inputText) => `Find relevant books or articles and provide a list of related titles with ${style} style citations based on the given text: "${inputText}". Only include sources you are aware of before September 2021.`;

  const handleStyleChange = (event) => {
    setCitationStyle(event.target.value);
  };

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await generateText({
        inputText: promptCitation(citationStyle, inputText),
        username: user.username,
      },
      true, // Dummy function for setShowModal
      false, // Dummy function for onUpdate
      false,
      false,
      false,
      true, // Set waitForCompletion to true
      true // Set skipModeration to true (optional, depends on your use case)
    );
  
      // Split the generated citations by line breaks
      const citations = response.split("\n");
  
      // Format the citations as an unordered list or numbered list
      const formattedCitations = citations
        .map((citation) => {
          const numberedItemRegex = /^\d+\.\s/;
          if (numberedItemRegex.test(citation)) {
            return `<li style="list-style-type: decimal;">${citation}</li>`;
          } else if (citation.trim() !== "") {
            return `<li>${citation}</li>`;
          } else {
            return "<br />";
          }
        })
        .join("");
  
      // Update the state variables
      setGeneratedText(response);
      setFormattedText(`<ol style="list-style-type: none;">${formattedCitations}</ol>`);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      alert(`Error during API call: ${error.message}`);
    }
  };
  
  // Add the return statement here.



  return (
    <div className="container py-5" style={{ backgroundColor: "#f5f5f5" }}>
      <h1 className="text-center mb-5" tabIndex="0">
        Citation Genie
      </h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="citationStyleSelect" style={{ fontWeight: "bold" }}>
            Select a citation style:
          </label>
          <select
            className="form-control"
            id="citationStyleSelect"
            value={citationStyle}
            onChange={handleStyleChange}
          >
            <option value="APA">
              APA: Author's name and year; reference list in alphabetical order.
            </option>
            <option value="MLA">
              MLA: Author's name and page number; works cited in alphabetical
              order.
            </option>
            <option value="Chicago">
              Chicago: Superscript number, foot/endnote with full information.
            </option>
            <option value="Harvard">
              Harvard: Author's name and year; reference list in alphabetical
              order.
            </option>
            <option value="Turabian">
              AMA: Superscript number, reference list in numerical order.
            </option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="textInput" style={{ fontWeight: "bold" }}>
            Paste your paper/text here or description of the subject:
          </label>
          <textarea
            className="form-control"
            id="textInput"
            placeholder="Paste your text - less than 500 words"
            value={inputText}
            onChange={handleInputChange}
            rows="5"
          />
        </div>
        <button
          className="btn btn-primary"
          type="submit"
          disabled={isLoading}
          aria-label="Summon Citation Genie"
        >
          {isLoading ? "Summoning the Genie ... may take a few seconds" : "Summon the Genie"}
        </button>
      </form>
  
      {isLoading && (
        <div className="mt-3">
          <ProgressBar animated now={100} />
        </div>
      )}
  
      {generatedText && (
        <div className="bg-light p-5 rounded mt-4 output-text" tabIndex="0">
          <h3 className="text-center mb-3">Citation Magic!</h3>
          {formattedText && (
            <div
              className="formatted-citations"
              dangerouslySetInnerHTML={{ __html: formattedText }}
            />
          )}
        </div>
      )}
      <p>WARNING: Always double check these works exist and match your subject</p>
    </div>
  );
  
};
export default Citation;