import { Container } from "react-bootstrap";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import CustomNav from "./component/Nav.js";
import LoginForm from "./component/LoginForm.js";
import SignupForm from "./component/SignupForm.js";
import Messages from "./component/Messages.js";
import Essay from "./component/essay.js";
import Quiz from "./component/quiz.js";
import EmojiStories from "./component/EmojiStories.js";
import How from "./component/how2.js";
import NotFound from "./component/NotFound.js";
import "./App.css";
import Account from "./component/useraccount.js";
import SplashPage from "./component/SplashPage.js";
import Citation from "./component/citations.js";
import MyStory from "./component/story.js";
import BlogList from "./component/AIblog.js";

function App() {
  return (
    <Container className="App" fluid>
      <Router>
        <CustomNav />
        <Routes>
          <Route path="/" element={<SplashPage />} />
          <Route path="/BlogList" element={<BlogList />} />
          <Route path="/how" element={<How />} />
          <Route path="/Citation" element={<Citation />} />
          <Route path="/MyStory" element={<MyStory />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/signup" element={<SignupForm />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/essay" element={<Essay />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/EmojiStories" element={<EmojiStories />} />
          <Route path="/useraccount" element={<Account />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </Container>
  );
}

export default App;
