import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import LogoutButton from './LogoutButton.js';

const CustomNav = () => {
  return (
    <Navbar bg="darkslategrey" expand="lg" variant="dark" className="w-100">
      <Container fluid>
        <Navbar.Brand as={Link} to="/">
          <img
            src="./favicon.ico"
            alt="Tutor Genie"
            className="tutor-genie-image img-fluid"
            style={{ maxHeight: '40px', width: 'auto', padding: '2%', backgroundColor: "darkgray" }}
          />
          Tutor Genie
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarNav" />
        <Navbar.Collapse id="navbarNav">
          <Nav className="ms-auto">
          <Nav.Item>
              <Nav.Link as={Link} to="/messages">
                Talk to Genie
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/Citation">
                Citation Genie
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/MyStory">
                Genie's Story
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/BlogList">
                AI Blog
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/EmojiStories">
                Emoji Game
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/quiz">
                Quiz Genie
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/essay">
                Essay Genie
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/how">
                How it Works
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/signup">
                Sign Up
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/login">
                Login
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNav;
