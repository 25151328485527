import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";

const clientID = "745264297251-ej4pbtr4k45s8ba1768gvg9883qk7acn.apps.googleusercontent.com";

const SignupForm = ({user}) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [age, setAge] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }
    try {
      const res = await axios.post('/signup', {
        username,
        email,
        age,
        password,
      });
    
      console.log(username, email, age, password)
      
      const { user: userData } = res.data;

      if (email === "momchrysti@gmail.com") {
        console.log(userData)
        navigate("/");
      } else {
        console.log(userData)
      }
      setErrorMessage("");
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("An error occurred during signup. Please try again.");
      }
    }
  };

  async function signInWithGoogle() {
    const auth2 = gapi.auth2.getAuthInstance();
  
    if (auth2) {
      const googleUser = await auth2.signIn();
      
      if (googleUser) {
        const authResponse = googleUser.getAuthResponse();
        
        if (authResponse) {
          const id_token = authResponse.id_token;
          return id_token;
        } else {
          console.error('authResponse is undefined');
        }
      } else {
        console.error('googleUser is undefined');
      }
    } else {
      console.error('auth2 is undefined');
    }
  }

  const handleGoogleSignup = async () => {
    try {
      const response = await signInWithGoogle();
      const userObject = jwt_decode(response);
      const { email } = userObject;

      const res = await axios.post('/api/user', {
        email,
      });

      const { exists, userId } = res.data;

      if (!exists) {
        const createRes = await axios.post('/api/signup', {
          email,
        });

        const { success, message } = createRes.data;

        if (!success) {
          throw new Error(message);
        }
      }
      window.location.href = `/signup?userId=${userId || email}&state=signup`;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/platform.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      gapi.load('auth2', () => {
        gapi.auth2.init({
          client_id: clientID,
        });
      });
    };
  }, []);
  

  return (
    <div
      className="container"
      style={{
        backgroundImage:
          "linear-gradient(to right, #1a1a1a, #0d47a1, #1a1a1a)",
      }}
    >
      <h1 className="mb-4 text-white">Sign up</h1>
      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
      <div className="form-group">
        <div class="col text-center">
          <h2 className="text-white">Free Trial! $0</h2>
          <h3 className="text-white">Only $0 FULL access to Tutor Genie for a limited time</h3>
          <h3 className="text-white"> Sign up now and see those grades soar!</h3>
        </div>
        <label htmlFor="username" className="text-white">
          Username
        </label>
        <input
          type="text"
          className="form-control"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor="email" className="text-white">
          Email
        </label>
        <input
          type="email"
          className="form-control"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor="age" className="text-white">
          Age
        </label>
        <input
          type="number"
          className="form-control"
          id="age"
          value={age}
          onChange={(e) => setAge(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor="password" className="text-white">
          Password
        </label>
        <input
          type="password"
          className="form-control"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor="confirm-password" className="text-white">
          Confirm Password
        </label>
        <input
          type="password"
          className="form-control"
          id="confirm-password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </div>
      <div className="form-group">
        <input
          type="checkbox"
          className="form-check-input"
          id="terms-and-conditions"
          required
        />
        <label
          htmlFor="terms-and-conditions"
          className="form-check-label text-white"
        >
          I agree to the{" "}
          <span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={handleShowModal}
            >
              Terms and Conditions
            </span>
          </label>
        </div>
        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
          Sign up
        </button>

      {/* Terms and Conditions Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Add your Terms and Conditions content here */}
          <p>
            By using this website, you agree to be bound by these Terms and
            Conditions. Please read them carefully. If you do not agree to be
            bound by            these Terms and Conditions, do not use this website.
            Terms and Conditions

            Introduction
            By accessing and using this website ("Site"), you agree to be bound by these Terms. These Terms apply to all visitors, users, and others who access or use the Site. If you do not agree with these Terms, please do not use the Site.

            Intellectual Property Rights
            All content on this Site, including but not limited to text, graphics, images, logos, and software, is the property of the Site owner or its content suppliers and protected by copyright and other intellectual property laws. Unauthorized use of the Site's content is strictly prohibited.

            User Responsibilities
            By using this Site, you agree to:

            a) Provide accurate and complete information when creating an account or using any services provided by the Site.

            b) Maintain the confidentiality of your account information, including your username and password.

            c) Comply with all applicable laws and regulations while using the Site.

            d) Not engage in any activity that may disrupt or interfere with the Site's functionality or security.

            e) Not attempt to gain unauthorized access to any part of the Site, other user accounts, or any computer systems or networks connected to the Site.

            f) Not use the Site for any illegal or unauthorized purposes.

            Limitation of Liability
            In no event shall the Site owner or its affiliates be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses, resulting from:

            a) The use or inability to use the Site;

            b) The cost of procurement of substitute goods and services resulting from any goods, data, information, or services purchased or obtained, or messages received or transactions entered into through or from the Site;

            c) Unauthorized access to or alteration of your transmissions or data;

            d) Any other matter relating to the Site.

            Indemnification
            You agree to indemnify, defend, and hold harmless the Site owner, its affiliates, and their respective officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees and costs, arising out of or in any way connected with your access to or use of the Site.

            Changes to the Terms
            The Site owner reserves the right to modify or update these Terms at any time without prior notice. By continuing to access or use the Site after such changes, you agree to be bound by the updated Terms. It is your responsibility to periodically review the Terms for any changes.

            Governing Law
            These Terms shall be governed by and construed in accordance with the laws of the United States of American, Florida, without giving effect to its conflict of laws provisions. You agree to submit to the exclusive jurisdiction of the courts located in United States of America, Florida, for the resolution of any disputes arising out of or relating to these Terms or your use of the Site.

            Contact Information
            If you have any questions or concerns regarding these Terms, please contact us at support@aitutorgenie.com.
          </p>
          {/* ... */}
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
            Sign up
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SignupForm;
