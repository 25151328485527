import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Row, Col, Alert } from 'react-bootstrap';

const Account = () => {
  const [user, setUser] = useState({
    _id: '',
    email: '',
    membershipStatus: '',
    membershipLevel: '',
    username: ''
  });

  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  useEffect(() => {
    fetch('/api/user-details', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json();
        } else {
          throw new Error(`Invalid content-type: ${contentType}`);
        }
      })
      .then((data) => {
        setUser({
          _id: data.user._id,
          email: data.user.email,
          membershipStatus: data.user.membership.status,
          membershipLevel: data.user.membership.level,
          username: data.user.username,
        });
      })
      .catch((err) => console.error(err));
  }, []);
  

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handlePasswordChange = (event) => {
    event.preventDefault();

    // Validate form data
    if (formData.newPassword !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    // Send API request to update password
    fetch('/api/user/password', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        currentPassword: formData.currentPassword,
        newPassword: formData.newPassword,
      }),
    })
      .then(async (response) => {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json();
        } else {
          throw new Error(`Invalid content-type: ${contentType}`);
        }
      })
      .then((data) => {
        setSuccess('Password changed successfully');
        setFormData({
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
        });
      })
      .catch((err) => {
        console.error(err);
        setError('Failed to change password');
      });
  };
console.log(user)
  return (
    <Container style={{ backgroundColor: "white" }}>
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <h2>Manage Account</h2>
  <a href="https://billing.stripe.com/p/login/28o5ma0tU3Ep49yeUU">Manage Payments on Stripe Here</a>        
          {success && <Alert variant="success">{success}</Alert>}
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handlePasswordChange}>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={user.email}
                readOnly
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Membership Status</Form.Label>
              <Form.Control
                type="text"
                name="membershipStatus"
                value={user.membershipStatus}
                readOnly
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Membership Level</Form.Label>
              <Form.Control
                type="text"
                name="membershipLevel"
                value={user.membershipLevel}
                readOnly
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                type="password"
                name="currentPassword"
                value={formData.currentPassword}
               
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                name="newPassword"
                value={formData.newPassword}
                
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                type="password"
                name="confirmNewPassword"
                value={formData.confirmPassword}
              
                required
              />
            </Form.Group>
            <Button type="submit" onClick={handlePasswordChange}>
  Submit
</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
  };  

export default Account;
