import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useAuthContext } from "../AuthContext";
import GoogleLoginButton from './GoogleLoginButton';

const exemptUsers = [
  "brayden",
  "chrysti541",
  "jalynbrowne"
];

const clientID = "745264297251-ej4pbtr4k45s8ba1768gvg9883qk7acn.apps.googleusercontent.com";

function LoginForm(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuthContext();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post("https://www.aitutorgenie.com/api/user/login", {
        username,
        password,
      });
  
      if (response.status === 200) {
        localStorage.setItem("token", response.data.token);
        login(response.data.token);
        navigate("/");
      }
    } catch (err) {
      alert("Error logging in " + err);
    }
  };

const handleGoogleLoginSuccess = async (response) => {
  const { token } = response;
  try {
    const { data } = await axios.post("/api/google/login", { id_token: token });
    const { token } = data;
    const decoded = jwt_decode(token);
    setAuthTokens(token, decoded);
    navigate("/");
  } catch (error) {
    console.error(error);
    setError("Google Login failed. Please try again.");
  }
};

  const handleGoogleLoginFailure = (error) => {
    setError("Google Login failed. Please try again.");
  };
  const handleSignupClick = () => {
    navigate('/signup');
  };
  return (
    <div className="container" style={{ backgroundColor: "#1a237e", color: "white" }}>
      <h1 className="text-center" style={{ paddingTop: "1rem" }}>Welcome to Tutor Genie!</h1>
      <div id="google-login-button" className="text-center">
        {/* <GoogleLoginButton
          clientId={clientID}
          onSuccess={handleGoogleLoginSuccess}
          onFailure={handleGoogleLoginFailure}
        /> */}
      </div>
      <p className="text-center" style={{ color: "#e0e0e0" }}></p>
      <form onSubmit={handleSubmit} style={{ padding: "1rem" }}>
        <div className="form-group">
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            className="form-control"
            id="username"
            value={username}
            placeholder="user1"
            onChange={(e) => setUsername(e.target.value)}
            style={{ backgroundColor: "#3f51b5", color: "white", borderColor: "white" }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            className="form-control"
            id="password"
            value={password}
            placeholder="password1"
            onChange={(e) => setPassword(e.target.value)}
            style={{ backgroundColor: "#3f51b5", color: "white", borderColor: "white" }}
          />
        </div>
        <div className="text-center" style={{ paddingBottom: '1rem' }}>
          <button type="submit" className="btn btn-primary mr-2">Log in</button>
          <button type="button" className="btn btn-secondary" onClick={handleSignupClick}>
  Sign up
</button>
        </div>
      </form>
      <h3 className='text-white'>Password Resets? Contact Support with the email you used to sign up: <a href="mailto:support@aitutorgenie.com" className="text-white">support@aitutorgenie.com</a></h3>
    </div>
  );
              }

              
              export default LoginForm;