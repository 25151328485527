import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

export default function How() {
  return (
    <div
      className="container"
      style={{
        backgroundImage:
          "linear-gradient(to right, #1a1a1a, #0d47a1, #1a1a1a)",
      }}
      role="main"
      aria-label="How to Use Tutor Genie"
    >
      <h1 className="text-white">How to Use Tutor Genie</h1>
      <h2 className="text-white">Frequently Asked Questions</h2>
      <h3 className='text-white'>Contact Support: <a href="mailto:support@aitutorgenie.com" className="text-white">support@aitutorgenie.com</a></h3>
      <a href="https://aitutorgenie.com/privacy.html"> Privacy Policy || Terms and Conditions </a>
      <div className="list-group">
        <div className="list-group-item" style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
          <h3 className="mb-1">1. How do I start a conversation with Tutor Genie?</h3>
          <p>
            To start a conversation with Tutor Genie, simply type your message in the
            message input box and press enter or click the send button. Tutor Genie will
            respond to your message automatically using the OpenAI language model.
          </p>
        </div>
        <div className="list-group-item" style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
          <h3 className="mb-1">2. How do I know this is safe for my child to use?</h3>
          <p>
            The text entered by the user is checked against a moderation policy to ensure that it is appropriate.
            If the text is not appropriate, the message will not be sent and you will be prompted to
            enter a different message.
            The AI is also instructed to not respond to questions about sex, drugs, or violence.
          </p>
        </div>
        <div className="list-group-item" style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
          <h3 className="mb-1">3. How do I end the conversation with Tutor Genie?</h3>
          <p>
            To end the conversation with Tutor Genie, click the "Logout" button at
            the bottom of the chat window. Or close the whole application.
          </p>
        </div>
        <div className="list-group-item" style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
          <h3 className="mb-1">4. How do I know the answer is correct?</h3>
          <p>
            Tutor Genie uses the latest AI technology to provide answers to your questions.
            This technology allows Tutor Genie to learn from the questions you ask and the
            answers you receive. It is limited to information publicly available up to 2021.
          </p>
        </div>
        <div className="list-group-item" style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
          <h3 className="mb-1">5. What kind of questions can it answer?</h3>
          <p>
            Unlike googling, Tutor Genie can answer questions about a wide variety of topics.
            It can answer questions about history, science, math, geography, sports, music,
            movies, TV shows, books, and more. It can also answer questions about the weather,
            the stock market, and the latest news. It can even answer questions about itself.
          </p>
        </div>
        <div className="list-group-item" style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
<h3 className="mb-1">6. Is this cheating?</h3>
<p>
AI Tutor Genie is specifically designed to provide cheat-free tutoring assistance. However, you should always check your school policy to see if using Tutor Genie is allowed.
Tutor Genie is not intended to be used as a substitute for studying. It is intended
to be used as a supplement to studying. You should use it to enhance your learning.
</p>
</div>
<div className="list-group-item" style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
<h3 className="mb-1">7. Can Tutor Genie help me with my homework?</h3>
<p>
Tutor Genie can help you understand concepts and provide guidance on various subjects.
However, it is important to use it responsibly and not rely solely on it to complete
your assignments. Always make sure to follow your school's guidelines regarding the use
of external resources.
</p>
</div>
<div className="list-group-item" style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
<h3 className="mb-1">8. What if I need to reset my password or have a billing question?</h3>
<p>
Please contact us at support@aitutorgenie.com for password resets. 
<a href="https://billing.stripe.com/p/login/28o5ma0tU3Ep49yeUU">Manage Payments on Stripe Here</a>
</p>
</div>
<div className="list-group-item" style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
<h3 className="mb-1">8. Can I learn more about OpenAI and the technology behind Tutor Genie?</h3>
<p>
Yes, you can learn more about OpenAI and the language models it develops by visiting their website:
{' '}
<a href="https://www.openai.com/" target="_blank" rel="noopener noreferrer">
https://www.openai.com/
</a>
</p>
</div>
<div className="list-group-item" style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
  <h3 className="mb-1">9. How do I create an account on Tutor Genie?</h3>
  <p>
    To create an account on Tutor Genie, visit the Signup page on our website, and fill out the required fields with your information to create the account. You will then be directed to Stripe to complete the payment. The first 14 days are free and you may cancel any time.
  </p>
</div>
<div className="list-group-item" style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
  <h3 className="mb-1">10. Can I use Tutor Genie on multiple devices?</h3>
  <p>
    Yes, you can use Tutor Genie on multiple devices. Once you have created an account, you can log in to your account from any device with an internet connection, including desktop computers, laptops, tablets, and smartphones. This makes it easy to access Tutor Genie anytime and anywhere you need it.
  </p>
</div>
<div className="list-group-item" style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
  <h3 className="mb-1">11. Is there a mobile app available for Tutor Genie?</h3>
  <p>
    Currently, there is no dedicated mobile app for Tutor Genie. However, the web-based application is designed to be responsive and mobile-friendly, allowing you to use it comfortably on your smartphone or tablet through your device's web browser.
  </p>
</div>
<div className="list-group-item" style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
  <h3 className="mb-1">12. What languages does Tutor Genie support?</h3>
  <p>
    Tutor Genie primarily supports English. However, the underlying AI technology has been trained on multiple languages, and it may be able to understand and respond to questions in other languages to some extent. The accuracy and depth of understanding may vary depending on the language and the complexity of the question.
  </p>
</div>
<div className="list-group-item" style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
  <h3 className="mb-1">13. How can I report errors or issues I encounter while using Tutor Genie?</h3>
  <p>
    If you encounter any errors or issues while using Tutor Genie, please contact our support team by sending an email to support@aitutorgenie.com. Be sure to include a detailed description of the issue, any error messages you received, and the steps you took leading up to the issue. Our support team will do their best to help you resolve the problem as soon as possible.
  </p>
</div>
<div className="list-group-item" style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
  <h3 className="mb-1">14. Can I customize Tutor Genie to focus on specific subjects or topics?</h3>
  <p>
    Yes! You can ask Tutor Genie about any subject as long as it meets our moderation policy and is appropriate for any age.
    </p>
</div>

<div className="list-group-item" style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
  <h3 className="mb-1">16. Is there a subscription plan or pricing for Tutor Genie, or is it free to use?</h3>
  <p>
   Tutor Genie offers a very affordable monthly rate of $9.99 per month after your free 14 day trial! That's less than the cost of 1 hour of human tutoring. How much is your sanity worth?
  </p>
</div>
</div>
</div>

  )
    }

