import { useState, useEffect } from "react";
import decode from "jwt-decode";

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  const login = (token) => {
    localStorage.setItem("token", token);

    const decodedToken = decode(token);

    setUser({
      id: decodedToken.id,
      email: decodedToken.email,
      googleId: decodedToken.googleId,
      username: decodedToken.username,
    });


    setIsAuthenticated(true);
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setUser(null);
    setIsAuthenticated(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const googleUser = JSON.parse(localStorage.getItem("user"));

    if (token) {
      const decodedToken = decode(token);

      setUser({
        id: decodedToken.id,
        email: decodedToken.email,
        googleId: decodedToken.googleId,
        username: decodedToken.username,
      });
  

      setIsAuthenticated(true);
    } else if (googleUser) {
      setUser({
        ...googleUser,
      });

      setIsAuthenticated(true);
    }
  }, []);

  return { isAuthenticated, user, login, logout };
};

export default useAuth;
