import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, LinkedinShareButton, LinkedinIcon } from 'react-share';

const MyStory = () => {
    const shareUrl = 'https://www.aitutorgenie.com/mystory';

    return (
        <Container>
            <Helmet>
                <title>My Story - AITutorGenie</title>
                <meta name="description" content="Learn about the story behind AITutorGenie, the AI-powered education platform that is changing the game." />
                <meta name="keywords" content="AI tutors, education, artificial intelligence, personalized learning, student engagement, AITutorGenie" />
            </Helmet>
            <Row className="justify-content-center">
                <Col md={10}>
                    <Card className="my-5">
                        <Card.Body>
                            <Card.Title className="text-center">
                                <h2>The Story Behind AITutorGenie</h2>
                            </Card.Title>
                            <Card.Text>
                                Once upon a time, in a land filled with technology and ideas, there was a mother of two of the most adorable little girls on Earth. She had spent 14 years working tirelessly for a non-profit, helping the elderly and disabled, building complex workflows for the agency, and juggling many programs to meet the clients' needs. But one day, she realized she needed a change. So, she took a leap of faith and decided to join UCF Bootcamp, diving headfirst into the world of technology.
                            </Card.Text>
                            <Card.Text>
                                As she embarked on her new journey, she stumbled upon ChatGPT - a groundbreaking AI with immense potential. Despite its imperfections, she found it to be the smartest tech humanity had ever created, with an impressive 87% accuracy rate! This sparked her curiosity, leading her to write articles about using the AI ethically and efficiently for learning.
                            </Card.Text>
                            <Card.Text>
                                One fateful day, while dropping her 11-year-old daughter off at school, she was called into the principal's office, accompanied by two towering police officers. To her shock, she discovered her daughter had been flagged for a social media post about guns - water guns, to be exact! 🤦‍♀️ As the school struggled to differentiate between harmless jokes and real threats, an idea took root: AITutorGenie.
                            </Card.Text>
                            <Card.Text>
                                The app's chat-based interface allows students to check their posts for safety before hitting "send." With state-of-the-art moderation and stringent training, AITutorGenie ensures no inappropriate content slips through. It even boasts a Quiz Generator that can create quizzes on any topic, from boy bands to avocados, and a Citation Generator that provides properly formatted citations for your research.
                            </Card.Text>
                            <Card.Text>
                                AITutorGenie's most remarkable achievement? It caught her daughter's innocent post, preventing a repeat of that distressing day. Now, kids can have a safety net when it comes to social media, all thanks to the magic of AITutorGenie.com!
                            </Card.Text> 
                            <img src="./smimage.png" alt="inappropriate text being rejected"></img>
                            <div className="text-center">
                                <FacebookShareButton url={shareUrl} quote="Check out this amazing story about AITutorGenie!" className="mr-2">
                                    <FacebookIcon size={32} round />
                                    </FacebookShareButton>
                                    <TwitterShareButton url={shareUrl} title="Check out this amazing story about AITutorGenie!" className="mr-2">
                                    <TwitterIcon size={32} round />
                                </TwitterShareButton>
                                <LinkedinShareButton url={shareUrl} title="Check out this amazing story about AITutorGenie!" className="mr-2">
                                    <LinkedinIcon size={32} round />
                                </LinkedinShareButton>
                            </div>
                            <div className="text-center">
                                <Button variant="primary" href="/signup">Experience the Magic Today!</Button>
                               
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};
export default MyStory;