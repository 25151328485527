// components/NotFound.js
import React from 'react';

const NotFound = () => {
  return (
    <div className="text-white container text-center mt-5">
    <h1>Looks like you haven't Logged in Yet</h1>
    <a href="https://www.aitutorgenie.com" className="btn btn-primary mt-3">Home Page - aitutorgenie.com</a>
  
    <h2 className="text-white mt-5">Free Trial for 14 days! Cancel any time</h2>
    <h3 className="text-white mt-5">Only $9.99 per month for FULL access to Tutor Genie for a limited time</h3>
    <h3 className="text-white mt-5">Costs less than 1 hour of human tutoring. Sign up now and see those grades soar!</h3>
  </div>
  
  );
};

export default NotFound;
