import React, { useState, useEffect } from "react";
import axios from "axios";
import generateText from '../openai.js';

const Quiz = ({ user }) => {
  const [quizType, setQuizType] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [correctAnswerIndices, setCorrectAnswerIndices] = useState(Array(10).fill(null));
  const [selectedAnswers, setSelectedAnswers] = useState(Array(10).fill(null));
  const [numCorrectAnswers, setNumCorrectAnswers] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [secondAnimation, setSecondAnimation] = useState(false);
  const [progress, setProgress] = useState(0);
  const [generatedText, setGeneratedText] = useState("");
  const [showGeneratedText, setShowGeneratedText] = useState(false);



  const handleQuizTypeChange = (event) => {
    setQuizType(event.target.value);
  };

  const handleGradeLevelChange = (event) => {
    setGradeLevel(event.target.value);
  };
  const incrementProgress = () => {
    let interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress === 100) {
          clearInterval(interval);
          return prevProgress;
        } else {
          return prevProgress + 1;
        }
      });
    }, 3000);
  };
  useEffect(() => {
    let progressTimer;
    if (loading) {
      progressTimer = setInterval(() => {
        incrementProgress();
      }, 120000 / 100);
    }
    return () => clearInterval(progressTimer);
  }, [loading]);
  const generateQuestions = async () => {
    const prompt = `Generate 10 multiple choice questions that are random and unique for each time this request is made for ${quizType} at grade ${gradeLevel}. No questions can rely on outside material or passages. Each question should have 4 answer choices, labeled A), B), C), and D) (Capitalize the first letter and follow by a parentheses). The prompt should end with a question mark, and the answer choices should be separated by a line break. Indicate the correct answer by adding a line "CorrectAnswer: " followed by the letter of the correct answer (A, B, C, or D). Separate each question and its correct answer by an empty line.`;
    const separator = "Choices:";
    // Call generateText function with the required parameters

    const generatedText = await generateText(
      { 
        username: user.username, 
        prompt: prompt 
      }, 
      false, // setShowModal
      null, // onUpdate
      false, // isSystemMessage
      false, // useSecret
      false, // includeRecentMessages
      true, // waitForCompletion
      false // skipModeration
    );
  console.log(generatedText)
  setGeneratedText(generatedText);
    // Check if generatedText is undefined or empty
    if (!generatedText || generatedText.trim() === '') {
      // Handle the error here
  
      return;
    }
  
    // Process the generated text as needed
    const lines = generatedText.split("\n");
    const questions = [];
    let currentQuestion = null;
    
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i].trim();
    
      if (line.match(/^\d+\)/)) {
        if (currentQuestion) {
          questions.push(currentQuestion);
        }
        currentQuestion = {
          prompt: line,
          choices: [],
        };
      } else if (line.match(/^[A-D]\)/)) {
        currentQuestion.choices.push(line.trim());
      } else if (line.startsWith("CorrectAnswer:")) {
        currentQuestion.answer = line.split(" ")[1].toUpperCase();

      }
    }
    
    if (currentQuestion) {
      questions.push(currentQuestion);
    }
    
  
    const formattedQuestions = questions.map((q) => {
      const prompt = q.prompt.substring(q.prompt.indexOf(" ") + 1);
      const choices = q.choices.map((choice) => {
        return {
          text: choice.substring(choice.indexOf(" ") + 1),
        };
      });
      
      const correctAnswer = q.answer;

      return { prompt, choices, answer: q.answer };

    });

    setCorrectAnswerIndices(formattedQuestions.map((q) => q.answer));
    setQuizQuestions(formattedQuestions);
    setLoading(false);
    setSecondAnimation(false)
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setProgress(0);
    incrementProgress();
  
    setTimeout(() => {
      setSecondAnimation(true);
    }, 9000);
  
    await generateQuestions();
  };
  const handleAnswerChange = (index, event) => {
    const newAnswers = [...selectedAnswers];
    newAnswers[index] = parseInt(event.target.value, 10);
    setSelectedAnswers(newAnswers);
  };
  const handleResults = async (event) => {
    event.preventDefault();
    let correctAnswers = 0;
    quizQuestions.forEach((question, index) => {
      if (selectedAnswers[index] === correctAnswerIndices[index].charCodeAt(0) - 65) {
        correctAnswers++;
      }
    });
    setNumCorrectAnswers(correctAnswers);
    setShowResults(true);
  
    // Show the generatedText
    setShowGeneratedText(true);
  };

    const handleNewQuiz = () => {
    setQuizType("");
    setGradeLevel("");
    setQuizQuestions([]);
    setCorrectAnswerIndices(Array(10).fill(null));
    setSelectedAnswers(Array(10).fill(null));
    setNumCorrectAnswers(0);
    setShowResults(false);
    setLoading(false);
    setProgress(0);
    };
    const resetQuiz = () => {
      setQuizType("");
      setGradeLevel("");
      setQuizQuestions([]);
      setLoading(false);
      setShowResults(false);
      setNumCorrectAnswers(0);
      setShowGeneratedText(false);
    };
    
    const renderQuestion = (question, index) => {
      return (
        <div key={index} className="col-md-6" style={{ backgroundImage: "linear-gradient(to right, #1a1a1a, #0d47a1, #1a1a1a)" }}>
          <h3 className="mt-4">{`${index + 1}. ${question.prompt}`}</h3>
          <div className="form-group">
            <select
              className="form-control"
              value={selectedAnswers[index] !== null ? selectedAnswers[index] : ""}
              onChange={(event) => handleAnswerChange(index, event)}
            >
              <option value="" disabled>
                Select an answer
              </option>
              {question.choices.map((choice, choiceIndex) => (
                <option key={choiceIndex} value={choiceIndex}>
                  {choice.text.trim()}
                </option>
              ))}
            </select>
          </div>
        </div>
      );
    };
    
    
    return (
      <div className="container-fluid bg-primary text-white p-5"
        style={{ backgroundImage: "linear-gradient(to right, #1a1a1a, #0d47a1, #1a1a1a)" }}
      >
        <h1 className="text-center mb-5">10 Question Quiz</h1>
        {loading && (
          <div>
            <div className="text-center mt-3">
              <div className="spinner-border text-light" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <p>Coming up with a custom quiz...</p>
              {secondAnimation && (
                <p>
                  This can take up to 1 min to get the questions, choices, and answers
                  based on your specific quiz need.
                </p>
              )}
            </div>
            <div className="progress">
              <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </div>
        )}
    
        <div className="row">
          <div className="quiz-container col-md-6 mx-auto">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="quizType"> </label>
                <h6>Quiz Subject (be as specific as possible)</h6>
                <p>to get new results try adding additional details such as Math-division, Science - space travel. You can even paste in a sample question and get new questions of the same type and subject.</p>
                <input
                  type="text"
                  className="form-control"
                  id="quizType"
                  placeholder="Math, Science, Language Arts, State Test (specify)"
                  value={quizType}
                  maxLength={150}
                  onChange={handleQuizTypeChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="gradeLevel">Grade Level</label>
                <input
                  type="text"
                  className="form-control"
                  id="gradeLevel"
                  placeholder="Enter Grade Level"
                  value={gradeLevel}
                  onChange={handleGradeLevelChange}
                />
              </div>
              <button
                type="submit"
                className="btn btn-lg btn-success mt-3"
                disabled={loading}
              >
                Generate Quiz
              </button>
            </form>
          </div>
        </div>
    
        {quizQuestions.length > 0 && (
          <div className="row mt-5">
            <div className="col-md-12">
              <form onSubmit={handleResults}>
                <div className="row">
                  {quizQuestions.map((question, index) =>
                    renderQuestion(question, index)
                  )}
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-lg btn-success mt-3">
                    Check Answers
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
    
        {showResults && (
          <div className="row mt-5">
            <div className="col-md-12">
              <h2 className="text-center">Results</h2>
              <p className="text-center">
                You got {numCorrectAnswers} out of {quizQuestions.length} correct!
              </p>
    
              {showGeneratedText && (
                <div>
                  <h4> Questions and Answers:</h4>
                  <p>{generatedText}</p>
                </div>
              )}
    
              <div className="mt-4">
                <h4>Incorrect Answers:</h4>
                {quizQuestions.map((question, index) => {
  if (selectedAnswers[index] !== correctAnswerIndices[index]) {
    return (
      <div key={index} className="mb-3">
        <p>
          <strong>{question.prompt}</strong>
        </p>
        <p>
          Correct Answer:{" "}
          {question.choices[correctAnswerIndices[index].charCodeAt(0) - 65].text}
        </p>
        <p>
          Your Answer:{" "}
          {selectedAnswers[index] !== null
            ? question.choices[selectedAnswers[index]].text
            : "Not answered"}
        </p>
      </div>
    );
  } else {
    return null;
  }
})}

</div>
<div className="text-center">
<button
           className="btn btn-lg btn-primary mt-3"
           onClick={resetQuiz}
         >
Try Again
</button>
</div>
</div>
</div>
)}

  </div>
);
    
    }

      export default Quiz;
      