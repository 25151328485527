import { Link } from 'react-router-dom';
import { useRef, useEffect, useState } from 'react';

function SplashPage() {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const messagesEndRef = useRef(null);

  const tutorMessages = [
    '🤖 Hello, how can I help you?',
    '🤖 An atom is the smallest constituent unit of matter that has the chemical properties of an element.',
    '🤖 The law of conservation of energy states that energy cannot be created or destroyed, only transformed from one form to another.',
    '🤖 The water cycle, also known as the hydrological cycle, is the continuous movement of water on, above, and below the surface of the Earth.',
    '🤖 Newton’s first law of motion states that an object at rest will remain at rest, and an object in motion will remain in motion with a constant velocity, unless acted upon by a net external force.',
    '🤖 The formula for the area of a circle is A = πr², where A is the area and r is the radius of the circle.',
    '🤖 An ecosystem is a community of living organisms (plants, animals, and microbes) in conjunction with the nonliving components of their environment (air, water, and mineral soil), interacting as a system.',
    '🤖 In computer programming, a loop is a sequence of instructions that is continually repeated until a certain condition is met.',
    '🤖 A chemical reaction is a process that leads to the transformation of one set of chemical substances to another.',
    '🤖 The difference between a hypothesis and a theory is that a hypothesis is a proposed explanation for an observation or phenomenon, while a theory is a well-substantiated explanation for the same.',
  ];

  const studentMessages = [
    '🧑‍🎓 Can you explain what an atom is?',
    '🧑‍🎓 What is the law of conservation of energy?',
    '🧑‍🎓 I’m confused about the water cycle. Can you help?',
    '🧑‍🎓 What is Newton’s first law of motion?',
    '🧑‍🎓 Can you explain the formula for the area of a circle?',
    '🧑‍🎓 I’m having trouble understanding ecosystems. Can you help me?',
    '🧑‍🎓 What is a loop in computer programming?',
    '🧑‍🎓 I need help with chemical reactions. Can you explain?',
    '🧑‍🎓 What’s the difference between a hypothesis and a theory?',
    '🧑‍🎓 Thank you for your help. You’re a great tutor!'
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % (tutorMessages.length * 2));
    }, 3000);

    return () => clearInterval(interval);
  }, [tutorMessages.length, studentMessages.length]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentMessageIndex]);

  const renderSplashMessages = () => {
    const messages = [];
    for (let i = 0; i <= currentMessageIndex; i++) {
      if (i % 2 === 0) {
        messages.push(
          <p
            key={`tutor-${i}`}
            className="text-right text-primary mb-1 px-3 py-2 rounded bg-light splash-tutor-message"
            >
            {tutorMessages[Math.floor(i / 2)]}
            </p>
            );
            } else {
            messages.push(
            <p
            key={`student-${i}`}
            className="text-left text-white mb-1 px-3 py-2 rounded bg-primary splash-student-message"
            >
            {studentMessages[Math.floor(i / 2)]}
            </p>
            );
            }
            }
            return messages;
            };
            
            const messageBoxRef = useRef(null);
            
            useEffect(() => {
            if (messageBoxRef.current) {
            messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
            }
            }, [currentMessageIndex]);

            return (
              <div className="splash-page d-flex flex-column align-items-center bg-light"
                style={{
                  height: "100%",
                  minHeight: "95vh",
                  backgroundImage: "url(./cat.jpg)",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                role="main"
                aria-label="Tutor Genie Splash Page"
              >
                <h1 className="display-4 text-primary my-4 large rise">Welcome to Tutor Genie!</h1>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 col-md-6 d-flex justify-content-center align-items-center mb-4">
                      <img
                        src="./kidjourney.png"
                        alt="Tutor Genie"
                        className="tutor-genie-image img-fluid mb-4"
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center">
                      <p className="large rise">Help Kids Study FAST</p>
                      <p className="small outline">Tutor Genie</p>
                      <div className="row mb-4">
                        <div className="col-12 text-center">
                          <Link to="/messages" className="btn btn-primary btn-lg">
                            Talk to Tutor Genie now!
                          </Link>
                        </div>
                      </div>
                      <div className="message-box border rounded border-primary p-3 mx-4 mb-4 w-100 bg-white shadow splash-message-box"
                        style={{ width: "60%", height: "70vh", overflowY: "auto", border: "6px solid black", display: "flex", flexDirection: "column", marginLeft: "10px" }}
                        ref={messageBoxRef}
                        tabIndex="0"
                        role="region"
                        aria-label="Chat Messages"
                      >
                        <div style={{ flex: "1 1 auto" }}>{renderSplashMessages()}</div>
                        <input type="text" className="form-control w-100" placeholder="Typing message..." disabled style={{ flex: "0 0 auto", marginTop: "auto" }} />
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-4 mb-4">
                        <a href="/EmojiStories" className="d-block link-bg text-center" aria-label="Emoji Game">
                          <img src="./emoji1.png" alt="Emoji Game" className="rounded-circle img-fluid mb-3" style={{ maxHeight: "120px" }} />
                          Emoji Game
                        </a>
                      </div>
                      <div className="col-md-4 mb-4">
                        <a href="/essay" className="d-block link-bg text-center" aria-label="Essay Helper">
                          <img src="./nobackgirl.png" alt="Essay Helper" className="rounded-circle img-fluid mb-3" style={{ maxHeight: "120px" }} />
                          Essay Helper
                        </a>
                      </div>
                      <div className="col-md-4 mb-4">
                        <a href="/quiz" className="d-block link-bg text-center" aria-label="Quiz Generator">
                          <img src="./DALLe.png" alt="Quiz Generator" className="rounded-circle img-fluid mb-3" style={{ maxHeight: "120px" }} />
                          Quiz Generator
                        </a>
                      </div>
                    </div>
                  </div>
                    <div className="row">
                      <div className="col-12 d-flex justify-content-between text-center mb-4">
                        <Link to="/signup" className="btn btn-primary btn-lg" style={{ width: "25%" }}>
                          Sign Up
                        </Link>
                        <Link to="/login" className="btn btn-primary btn-lg" style={{ width: "25%" }}>
                        Login
                      </Link>
                    </div>
                  </div>
                  <div className="container">
  <div className="row">
  <div className="col-12 col-md-6 mb-4 d-flex justify-content-center align-items-center">
      <iframe
        width="349"
        height="621"
        src="https://www.youtube.com/embed/h1BCOL6ph0s"
        title="www.aitutorgenie.com"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </div>
    <div className="col-12 col-md-6 mb-4 d-flex justify-content-center align-items-center">
      <img src="./mc.jpg" className="img-fluid mb-4 mb-md-0" />
</div>
    <div className="col-12 col-md-12 mb-8 d-flex justify-content-center align-items-center">
    <iframe width="1583" height="580" src="https://www.youtube.com/embed/iCWc2_CAGjk" title="AItutorgenie com" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <br />
<br />
    </div>
  
    <div className="col-12 col-md-6 mb-4">
  <img src="./quizcustom.png" className="img-fluid mb-4 mb-md-0" style={{ width: "90%" }} />
</div>
<div className="col-12 col-md-6 mb-4 d-flex flex-column justify-content-center align-items-center">
  <h3 className="text-center text-light bg-dark bg-opacity-75 px-3 py-2 mb-3">Generate a custom quiz on ANY topic for ANY grade level</h3>
  <img src="./popquiz.png" className="img-fluid mb-4 mb-md-0" style={{ width: "70%" }} />
</div>

    <div className="row">
    <br />
    {'\n'}
  <div className="col-12 col-md-6 mb-4 d-flex flex-column justify-content-center align-items-center">
  {'\n'}
  <h3 className="text-center text-light bg-dark bg-opacity-75 px-3 py-2 mb-3">
    Ask Any Question 24/7!  Starter Prompts included!
    </h3>
    <img
      src="./socialmedia.png"
      className="img-fluid mb-4 mb-md-0"
      style={{ width: "90%" }}
    />
  </div>
  <div className="col-12 col-md-6 mb-4 d-flex flex-column justify-content-center align-items-center">
    <h3 className="text-center text-light bg-dark bg-opacity-75 px-3 py-2 mb-3">
    Introducing a Cheat-Free Solution: Your Personal Writing Coach Awaits!
    </h3>
    <img
      src="./essaygenie.png"
      className="img-fluid mb-4 mb-md-0"
      style={{ width: "90%" }}
    />


  </div>
</div>

  </div>
</div>
<div className="col-12 col-md-10 mb-6 d-flex flex-column justify-content-center align-items-center">
    <h3 className="text-center text-light bg-dark bg-opacity-75 px-3 py-2 mb-3">
  Don't get stuck trying to figure out if the homework is art or math, Let Tutor Genie Help!
    </h3>
    <img
      src="./colors.png"
      className="img-fluid mb-4 mb-md-0"
      style={{ width: "90%" }}
    />


  </div>

<h1 className="text-center text-light bg-dark bg-opacity-75 px-3 py-2 mb-3"> Unlock 24/7 Tutor Genie, Custom Quiz Generator, and Essay Genie Sign up for Free – Unbeatable Value in One Comprehensive Package!</h1>
<h2 className="text-center text-light bg-dark bg-opacity-75 px-3 py-2 mb-3">Experience Exceptional AI Tutoring </h2>

                </div>
                <div className="container">
      <div className="row">
        {/* Review Card 1 */}
        <div className="col-md-6 mb-4">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <p className="lead mb-0">-Mom of 8 year old</p>
                <div className="stars ml-2">
                  <img src="./yellow-star.png" alt="Star" width="20" height="20" />

                  <img src="./yellow-star.png" alt="Star" width="20" height="20" />

                  <img src="./yellow-star.png" alt="Star" width="20" height="20" />

                  <img src="./yellow-star.png" alt="Star" width="20" height="20" />

                  <img src="./yellow-star.png" alt="Star" width="20" height="20" />

                </div>
              </div>
              <p>"Thank you Tutor Genie for your help! My daughter's grades have improved significantly since we started working with this app. Although we still have some work to do, we're happy to see her progress from struggling with C's and D's to achieving A's and B's. It's clear that your program has made a positive impact. My daughter has been diligent with the practice quizzes and checking her homework before submitting it. Additionally, we were pleased to see an improvement in her State Test scores. We're grateful for your support and the tools you provided to help her succeed."</p>
            </div>
          </div>
        </div>

        {/* Review Card 2 */}
        <div className="col-md-6 mb-4">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <p className="lead mb-0">-Dad of a 9 year old</p>
                <div className="stars ml-2">
                  <img src="./yellow-star.png" alt="Star" width="20" height="20" />

                  <img src="./yellow-star.png" alt="Star" width="20" height="20" />

                  <img src="./yellow-star.png" alt="Star" width="20" height="20" />

                  <img src="./yellow-star.png" alt="Star" width="20" height="20" />

                  <img src="./yellow-star.png" alt="Star" width="20" height="20" />

                </div>
              </div>
              <p>"I am beyond impressed with Tutor Genie's live AI tutor feature. Gone are the days when I used to spend hours scouring Google and YouTube to help my child with their math homework. Now, with just a few clicks, we can get the answers we need any time of day."</p>
            </div>
          </div>
        </div>
      </div>
    </div>
                <a href="https://aitutorgenie.com/privacy.html"> Privacy Policy || Terms and Conditons </a>
              </div>
            );
                }
                export default SplashPage;
