import { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import generateText from '../openai.js';
import axios from 'axios';
import he from 'he';
import { useAuthContext } from "../AuthContext.js";
import MarkdownIt from 'markdown-it';

const smileIcon = './roboticon.png';

const md = new MarkdownIt({
  html: true,
  breaks: true,
  linkify: true,
  typographer: false,
});

md.renderer.rules.list_item_open = (tokens, idx, options, _, self) => {
  return options.xhtmlOut ? '<li>\n' : '<li>';
};

const Messages = ({
  user = {},
  defaultMessages = [
    { author: { id: 'system', username: 'System' }, text: "What are we learning today?" },
  ],
}) => {
  const [inputText, setInputText] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [messages, setMessages] = useState(defaultMessages);
  const [selectedOption, setSelectedOption] = useState('');
  const [finalFormattedMessage, setFinalFormattedMessage] = useState('');
  const messagesEndRef = useRef(null);
  const { token } = useAuthContext();
  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setInputText('');
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && inputText.trim() !== '') {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!inputText) return;
  
    const userMessage = {
      author: { id: user.id, username: user.username },
      text: `${selectedOption} ${inputText}`,
    };
  
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    scrollToBottom();
    localStorage.setItem("messages", JSON.stringify(messages.slice(-3)));
  
    let messagetexts = [];
    const systemMessage = {
      author: { id: 'system', username: 'System' },
      text: '',
    };
  
    setMessages((prevMessages) => [...prevMessages, systemMessage]);
  
    function formatIncomingText(text) {
      const formattedText = text.replace(/(\d+)\s*\.\s*/g, '\n$1. ');
      const trimmedText = formattedText.replace(/ +/g, ' ');
      return trimmedText;
    }
  
    generateText(
      {
        username: `Name: ` + user.username,
        inputText: `${selectedOption} ${inputText}`,
        context: JSON.parse(localStorage.getItem('messages')).map(message => message.text).join(' ')
      },
      setShowModal,
      async (text, isInitialtext, isFinaltext) => {
        console.log(text)
        messagetexts.push(text.trim());
    
        setMessages((prevMessages) => {
          const lastSystemMessageIndex = prevMessages.length - 1;
          const updatedSystemMessage = {
            ...prevMessages[lastSystemMessageIndex],
            text: prevMessages[lastSystemMessageIndex].text + `<span style="white-space: nowrap;">${text.trim()}</span> `,
          };
    
          return [
            ...prevMessages.slice(0, lastSystemMessageIndex),
            updatedSystemMessage,
            ...prevMessages.slice(lastSystemMessageIndex + 1),
          ];
        });
    
        if (isFinaltext) {
          const finalText = formatIncomingText(messagetexts.join(' '));
          const formattedMessage = md.render(finalText);
          console.log("Formatted text: ", formattedMessage);
          setMessages((prevMessages) => {
            const lastSystemMessageIndex = prevMessages.length - 1;
            const updatedSystemMessage = {
              ...prevMessages[lastSystemMessageIndex],
              text: formattedMessage || prevMessages[lastSystemMessageIndex].text, // Use formattedMessage if it's defined, otherwise use the original text
            };
        
            const updatedMessages = [
              ...prevMessages.slice(0, lastSystemMessageIndex),
              updatedSystemMessage,
              ...prevMessages.slice(lastSystemMessageIndex + 1),
            ];
        
            return updatedMessages;
        
          });
        }
      },
      true,
      true,
      true,
      false,
      false
    );
    
  
    setInputText("");
  };
  

const scrollToBottom = () => {
  if (messagesEndRef.current) {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  }
};

useEffect(() => {
  const lastMessage = messages[messages.length - 1];
  if (lastMessage && lastMessage.author.id === 'system') {
    const userMessage = messages[messages.length - 2];
    if (userMessage && userMessage.author.id === user.id) {
     
    }
  }
}, [messages]);
useEffect(() => {
  scrollToBottom();
}, [messages]);

// Error handling modal
const [showErrorModal, setShowErrorModal] = useState(false);
const handleCloseErrorModal = () => setShowErrorModal(false);
const handleShowErrorModal = () => setShowErrorModal(true);

axios.interceptors.response.use(null, error => {
  if (error.response.status === 500) {
    handleShowErrorModal();
  }
  return Promise.reject(error);
});
      
  return (
      <Container className="message-container">
        <div className="messages-box flex-grow-1" style={{ overflowY: 'scroll', minHeight: '450px', maxHeight: '600px', fontSize: "1.3rem", fontWeitht: "400" }}>
          {(messages || []).map((message, index) => (
            <Row
              key={index}
              className={`message ${
                message.author.id === user.id
                  ? 'user-message justify-content-end'
                  : 'bot-message justify-content-start'
              }`}
            >
              <Col xs="auto" className="message-text">
                {message.author.id !== user.id && (
                  <img src={smileIcon} alt="Bot Icon" className="bot-icon" style={{ marginRight: '5px', width: '30px', height: '25px' }} />
                )}
                <div className={`text-bubble ${message.author.id === user.id ? 'user-bubble' : 'bot-bubble'}`}>
  <div
   dangerouslySetInnerHTML={{ __html: message.text }} // Update this line
  />
  <div ref={messagesEndRef} />
</div>
              </Col>
            </Row>
          ))}
          
        </div>
        <Form onSubmit={handleSubmit} className="messages-input-form">
  <Container fluid>
    <Row>
      <Col sm={3} className="mb-3">
        <Form.Group controlId="dropdownInput">
          <Form.Label style={{ fontWeight: "bold", color: "black",  backgroundColor: "rgba(255, 255, 255, 0.75)" }}>Prompt: </Form.Label>
          <Form.Control as="select" value={selectedOption} onChange={handleOptionChange}>
            <option>" "</option>
            <option>Explain </option>
            <option>List </option>
            <option>How to </option>
            <option>List funny facts about: </option>
            <option>Compare </option>
            <option>Why </option>
            <option>Break it down </option>
            <option>Describe </option>
            <option>Explain in kindergarten terms </option>
            <option>Explain with examples </option>
            <option>What  </option>
            <option>Spellcheck </option>
            <option>Define </option>
            <option>Is this safe to post this on Social Media? This --</option>
            <option>Brainstorm ideas about:</option>
            <option>List 10 engaging titles for: </option>
            <option>Create a story about: </option>
<option>Can a human live off this food forever? </option>

<option>Can you find a rhyme for: </option>
<option>What's the difference between: </option>
<option>Help me solve this puzzle: </option>
<option>Write a poem about: </option>
<option>What are some fun activities for: </option>
<option>What is the history of: </option>
<option>Find the meaning of this word: </option>
<option>Explain the scientific concept behind: </option>
<option>What are the benefits of: </option>
<option>What is the role of: </option>
<option>How does this process work: </option>
<option>What are the key features of: </option>
<option>Explain the cultural significance of: </option>
<option>Discuss the impact of: </option>
<option>Talk to me as if you are King Arthur (from British legend and literature) </option>
<option>Talk to me as if you are Cinderella (from European folklore) </option>
<option>Talk to me as if you are Gulliver (from Gulliver's Travels by Jonathan Swift) </option>
<option>Talk to me as if you are Ebenezer Scrooge (from A Christmas Carol by Charles Dickens) </option>
<option>Talk to me as if you are Harriet Tubman (a historical figure and American abolitionist) </option>
<option>Talk to me as if you are Don Quijote (from de la Mancha by Miguel de Cervantes) </option>
<option>Mimic Mickey Mouse while answering this question</option>
          </Form.Control>
        </Form.Group>
      </Col>
      <Col sm={9} className="mb-3 d-flex">
        <Form.Group controlId="messageInput" className="flex-grow-1">
          <Form.Label style={{ fontWeight: "bold", color: "black",  backgroundColor: "rgba(255, 255, 255, 0.75)" }}>Message<span className="text-danger">*</span> </Form.Label>
          <Form.Control
            type="text"
            value={inputText}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Type your message to Tutor Genie here ... less than 250 words"
          />
        </Form.Group>
        <div className="d-flex align-items-end justify-content-end">
          <Button variant="primary" type="submit">
            Send
          </Button>
        </div>
      </Col>
    </Row>
  </Container>
</Form>


      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Alert</Modal.Title>
  </Modal.Header>
  <Modal.Body>Genie doesn't understand that question. Ask in a different way</Modal.Body>
  <Modal.Footer>
    <Button variant="primary" onClick={handleCloseModal}>
      OK
    </Button>
  </Modal.Footer>
</Modal>
<>
  
    <Modal show={showErrorModal} onHide={handleCloseErrorModal}>
      <Modal.Header closeButton>
        <Modal.Title>Error</Modal.Title>
      </Modal.Header>
      <Modal.Body>There has been an error. Please refresh the page.</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseErrorModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </>
    </Container>
  );
        };  

export default Messages;